<template>
    <v-row class="ma-0 pa-0">
        <v-col md="2" sm="12" cols="12">
            <Sidebar></Sidebar>
        </v-col>
        <v-col md="10" sm="12">
            <div class="headline">
                Staff By Subject Area
            </div>
            <v-expansion-panels style="z-index: 0;" accordion tile multiple>
                <v-expansion-panel v-for="i in subjects">
                    <v-expansion-panel-header>
                        <h4>
                            {{i.Name}}
                        </h4>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <FilterableDataTable :headers="headers"
                                             :items="SubItems(i.SubjectId)"
                                             item-key="SubjectAreaId"
                                             :sort-by="['SubjectArea']"
                                             :sort-desc="[false]"
                                             :loading="loading"
                                             disable-pagination
                                             hide-default-footer
                                             loading-text="Loading... Please wait"
                                             class="elevation-1">
                            <template v-slot:item.Research="{ item }">
                                <span v-for="(i,il) in Research(item.User)">
                                    <router-link :to="'/Staff/Profile/' + i.UserId">{{i.Name}}<span v-if="il< Research(item.User).length-1">, </span></router-link>
                                </span>
                            </template>
                            <template v-slot:item.Fiscal="{ item }">
                                <span v-for="(i,il) in Fiscal(item.User)">
                                    <router-link :to="'/Staff/Profile/' + i.UserId">{{i.Name}}<span v-if="il< Fiscal(item.User).length-1">, </span></router-link>
                                </span>
                            </template>
                        </FilterableDataTable>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>
    </v-row>
</template>
<script>
    const Sidebar = () => import("@/Menus/Sidebar.vue");
    const FilterableDataTable = () => import("@/Components/FilterableDataTable.vue");

    export default {
        name: 'SubjectArea',
        components: {
            Sidebar, FilterableDataTable
        },
        methods: {
            LoadData()
            {
                this.loading = true;

                fetch('/api/UserSubjectAreas',
                    {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    })
                    .then(res =>
                    {
                        this.loading = false;

                        if (res.ok)
                            return res.json();
                        else
                            return res.text().then(r => Promise.reject(r));
                    })
                    .then(response =>
                    {
                        response.subjectAreas.forEach(x =>
                        {
                            x.Research = this.Research(x.User).map(y => y.Name).toString();
                            x.Fiscal = this.Fiscal(x.User).map(y => y.Name).toString();
                        });

                        this.subjects = response.subjects;
                        this.items = response.subjectAreas;
                    })
                    .catch(error =>
                    {
                        throw new Error(error);
                    })
                    .finally(_ =>
                    {
                        this.loading = false;
                    });
            },
            SubItems(subjectId)
            {
                return this.items.filter(x => x.SubjectId == subjectId);
            },
            Fiscal(Users)
            {
                return Users.filter(x => (x.StaffGroup1 == 'Fiscal') ||
                    (x.StaffGroup1 == 'Management' && x.Title.indexOf('Chief Fiscal & Program Analyst') > -1) ||
                    (x.StaffGroup1 == 'Management' && x.Title.indexOf('Chief Fiscal Analyst') > -1));
            },
            Research(Users)
            {
                return Users.filter(x => (x.StaffGroup1 == 'Research') ||
                    (x.StaffGroup1 == 'Management' && (x.Title.indexOf('Chief Research & Legal Analyst') > -1) ||
                        (x.Title == 'Code Counsel') ||
                        x.Title == 'Acting Chief Research & Legal Analyst/Code Counsel') ||
                    (x.StaffGroup1 == 'Management' && x.Title == 'Director'));
            },
        },
        data: function ()
        {
            return {
                headers: [
                    { text: 'Subject', value: 'SubjectArea', width: '*' },
                    { text: 'Research Staff', value: 'Research', width: '50ch' },
                    { text: 'Fiscal Staff', value: 'Fiscal', width: '50ch' },
                ],
                subjects: [],
                items: [],
                loading: true,
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        computed: {
            Title: function ()
            {
                let title = 'Staff by Subject Area';
                return title;
            },
            Description: function ()
            {
                return this.Title;
            }
        },
        metaInfo()
        {
            return {
                title: this.Title,
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: this.Description },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style></style>
