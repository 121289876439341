<template>
    <v-row>
        <v-col cols="12">
            <div class="headline">Agendas</div>
            <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
            <v-list>
                <v-list-item v-for="i in UniqueDates" :key="i">
                    <v-btn block rel="noopener" :href="combindAgendaURL(i)">Committee Agendas for {{MinDatePretty(i)}}</v-btn>
                </v-list-item>
            </v-list>
        </v-col>
    </v-row>
</template>
<script>
    import moment from 'moment'

    export default {
        name: 'CombinedAgendas',
        props: ['CommitteeId'],
        components: {

        },
        methods: {
            switchTab(item) {
                this.filterValue = item.value;
            },
            LoadData() {
                this.loading = true;
                if (this.currentRoute.includes('/Interim')) {

                } else {
                    fetch('/api/SessionCommittees/CommitteeAgendas/' + this.CommitteeId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res => {
                        this.loading = false;

                        if (res.ok) 
                        {
                            return res.json();
                        }
                        else
                        {
                            return res.text().then(r => Promise.reject(r));
                        }
                        
                    }).then(response => {
                        this.YearCommittee = EncodeDatetimes(response);
                        if (this.YearCommittee.CommitteeAgendas != null) {
                            var dates = this.YearCommittee.CommitteeAgendas.map(z => moment(z.DocumentDate).format('MM/DD/YYYY')).reverse();
                            this.UniqueDates = [new Set(dates)][0];
                        }

                    }).catch(error => {
                        throw new Error(error);
                    });
                }
            },
            MeetingTimePretty(Time) {
                if (Time != undefined) {
                    return moment(Time).format('h:mm A');
                } else {
                    return null;
                }
            },
            DatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD h:mm A');
                } else {
                    return null;
                }
            },
            MinDatePretty(Date) {
                if (Date != undefined) {
                    return moment(Date).format('M/DD');
                } else {
                    return null;
                }
            },
            openDocumentHTML(DocumentId) {
                //return Promise.resolve("<p>STring that you want me to write</p>");
                return fetch('/api/Documents/' + DocumentId + '.html', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res => {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201) {
                        return res.text();
                    }
                    else {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response => {
                    return response;
                }).catch(error => {
                    throw new Error(error);
                });
            },
            async expandItem(item) {
                if (item.value && item.item.HTML == null) {
                    item.item.HTML = await this.openDocumentHTML(item.item.DocumentId);
                }
            },
            rowClick(item, item1) {
                var temp = {
                    item: item,
                    value: !item1.isExpanded
                };
                if (!item1.isExpanded) {
                    this.expandItem(temp);
                    if (item.DocumentTypeId == 3 || item.DocumentTypeId == 5) {
                        this.expandedAgendas.push(item);
                    } else {
                        this.expandedMinutes.push(item);

                    }
                } else {
                    let testing = this.expandedAgendas.findIndex(x => x.DocumentId == item.DocumentId);
                    if (item.DocumentTypeId == 3 || item.DocumentTypeId == 5) {

                        this.expandedAgendas.splice(testing, 1);
                    } else {
                        this.expandedMinutes.splice(testing, 1);
                    }
                }
            },
            combindAgendaURL(item) {
                var docs = this.YearCommittee.CommitteeAgendas.filter(x => moment(x.DocumentDate).format('MM/DD/YYYY') == moment(item).format('MM/DD/YYYY'));
                var str = "";
                docs.forEach(i => {
                    str += "&Ids=" + i.DocumentId;
                });
                str = str.substring(1, str.length);
                return this.$MyLRCUrl.value + 'api/Documents/Combined.pdf?' + str;
            },
            isDateInArray(needle, haystack) {
                for (var i = 0; i < haystack.length; i++) {
                    if (needle.getTime() === haystack[i].getTime()) {
                        return true;
                    }
                }
                return false;
            }
        },
        data: function () {
            return {
                loading: true,
                loadingHTML: true,
                filterValue: 'Detail',
                UniqueDates: [],
                HouseRulesURL: null,
                SenateRulesURL: null,
                JointRulesURL: null,
                SenateSeatingChartURL: null,
                HouseSeatingChartURL: null,
                HouseDirectoryURL: null,
                SenateDirectoryURL: null,
                tabs: [
                    {
                        value: 'Detail', type: 'All'
                    },
                    {
                        value: 'Agendas', type: 'All'
                    },
                    {
                        value: 'Minutes & Audio', type: 'All'
                    },
                    {
                        value: 'Bills', type: 'Session'
                    },
                    {
                        value: 'Hearing Documents', type: 'All'
                    },
                    {
                        value: 'Committee Agendas', type: 'Session'
                    },
                ],
                headers: [{ text: 'Date', value: 'DocumentDate' }, { text: '', value: 'DocumentId' }, { text: '', value: 'data-table-expand' },],
                billHeaders: [{ text: 'Bill', value: 'BillId' }, { text: 'Title', value: 'Title' },],
                expandedAgendas: [],
                expandedMinutes: [],
                CommitteeMemberTypes: [],
                YearCommittee: {
                    Session: {
                        Year: '',
                        SessionId: null,
                    },
                    Committee: {
                        MeetingTime: '',
                        Room: {
                            Room: '',
                        },
                        Days: '',
                    },
                },
                Documents: [],
            }
        },
        mounted: function () {
            this.LoadData();
        },
        watch: {
            CommitteeId: function () {
                this.LoadData();
            }
        },
        computed: {
            CommitteeType: function () {
                //switching between session and interm
                if (this.currentRoute.includes('/Interim')) {
                    return "Interim";
                } else {
                    return "Session";
                }
            },
            currentRoute: function () {
                return this.$route.path;
            },
            filteredTabs: function () {
                if (this.currentRoute.includes('/Interim')) {
                    return this.tabs.filter(x => x.type != 'Session');
                } else {
                    if (this.YearCommittee.Committee.FullBody) {
                        return this.tabs;
                    } else {
                        return this.tabs.filter(x => x.value != 'Committee Agendas');

                    }
                }
            },
            Senators: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    return this.YearCommittee.CommitteeMembers.filter(x => x.MemberType == 'S').sort((a, b) => (a.Member.LastName > b.Member.LastName) ? 1 : -1);
                } else {
                    return [];
                }
            },
            Representatives: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    return this.YearCommittee.CommitteeMembers.filter(x => x.MemberType == 'H').sort((a, b) => (a.Member.LastName > b.Member.LastName) ? 1 : -1);
                } else {
                    return [];
                }
            },
            Agendas: function () {
                var agendaDocTypeId = [3, 5];
                return this.YearCommittee.Documents.filter(x => agendaDocTypeId.includes(x.DocumentTypeId));
            },
            Minutes: function () {
                var minDocTypeId = [2, 4];
                return this.YearCommittee.Documents.filter(x => minDocTypeId.includes(x.DocumentTypeId));
            },
            Chair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Chair');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            ViceChair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Vice-Chair');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            CoChair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Co-Chair');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }

            },
            LeadCoChair: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'Lead Co-Chair');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            ExOfficio: function () {
                if (this.YearCommittee.CommitteeMembers != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.CommitteeMemberType == 'ex officio');
                    if (res.length > 0) {
                        return res[0];
                    } else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            ChiefClerk: function () {
                if (this.YearCommittee.SessionStaff != undefined) {
                    return this.YearCommittee.SessionStaff.ChiefClerk;

                } else {
                    return {};
                }
            },
            President: function () {
                if (this.YearCommittee.Committee != undefined) {
                    var res = this.YearCommittee.CommitteeMembers.filter(x => x.Position == 'President Pro Tempore');
                    if (res.length > 0) {
                        return res[0];
                    }
                    else {
                        return {};
                    }
                } else {
                    return {};
                }
            },
            Secretary: function () {
                if (this.YearCommittee.SessionStaff != undefined) {
                    return this.YearCommittee.SessionStaff.Secretary;

                } else {
                    return {};
                }
            },
        }
    };
</script>
<style></style>