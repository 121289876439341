<template>
    <v-card flat class="py-0 sm-mt-4">
        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
        <v-alert outlined v-if="User && User.Deleted" type="info">This profile is for historical purposes only.  Please see the <router-link :to="'/Staff/Listing'">staff listing</router-link> for current staff.</v-alert>

        <v-card-text>
            <v-layout row wrap>
                <v-flex sm5 xs12 cols="12">
                    <div class="text-center">
                        <div v-if="User && User.Picture">
                            <div v-if="User.Picture && !bigImage && !currentRoute.includes('profile')">
                                <div class="v-avatar" style="border-radius: 9999px;">
                                    <div :aria-label="'A professional photo of ' + User.FirstName + ' ' + User.LastName"
                                         role="img"
                                         class="v-image v-responsive"
                                         style="height: 90px; width: 75px;">
                                        <div class="v-responsive__sizer" style="padding-bottom: 166.667%;"></div>
                                        <div class="v-image__image v-image__image--cover" :style="generateBackgroundImage()"></div>
                                        <div class="v-responsive__content"></div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="User.Picture && bigImage && !currentRoute.includes('profile')">
                                <v-avatar class="profile"
                                          color="grey"
                                          width="100"
                                          height="125">
                                    <v-img transition="false" :src="User.Picture.toLowerCase()"></v-img>

                                </v-avatar>
                            </div>
                            <div v-if="User.Picture && currentRoute.includes('profile')">
                                <v-avatar class="profile"
                                          color="grey"
                                          width="200"
                                          height="250"
                                          tile>
                                    <v-img transition="false" :src="User.Picture.toLowerCase()"></v-img>

                                </v-avatar>
                            </div>
                        </div>
                    </div>
                </v-flex>
                <v-flex sm7 xs12 cols="12">
                    <div v-if="User && User.LastName && User.FirstName">
                        <div class="font-weight-medium"><b>{{User.FirstName}} {{User.LastName}}</b></div>
                        <div><i>{{User.Title}}</i></div>
                        <div v-if="!currentRoute.includes('profile')"><v-icon>{{person}}</v-icon> <router-link :to="'/Staff/Profile/' + User.UserId">View Profile</router-link></div>
                        <div v-if="expand && User && !User.Deleted"><v-icon>{{email}}</v-icon><a rel="noopener" :href="'mailto:' +User.Email">{{User.Email}}</a></div>
                        <div v-if="currentRoute.includes('profile')">
                            <div v-if="!expand && User && !User.Deleted"><v-icon>{{email}}</v-icon><a rel="noopener" :href="'mailto:' +User.Email">{{User.Email}}</a></div>
                            <div v-if="User && !User.Deleted"><v-icon>{{phone}}</v-icon> <span v-if="expand"><b>Office: </b> </span><a rel="noopener" :href="'tel:605-773-3251' ">(605)773-3251</a></div>

                            <div v-if="User.SubjectAreas.length >0">
                                <br />
                                <div><b>Subject Area(s):</b></div>
                                <template v-for="(s,i) in User.SubjectAreas">
                                    <span v-if="i != User.SubjectAreas.length-1">{{s.SubjectArea}}, </span>
                                    <span v-else>{{s.SubjectArea}}</span>
                                </template>
                            </div>

                            <div v-if="User.UserSessionCommittees.length >0">
                                <br />
                                <div><b>Session Committee(s):</b></div>
                                <template v-for="(s,i) in User.UserSessionCommittees">
                                    <router-link :to="'/Session/Committee/' + s.SessionCommitteeId">{{s.FullName}}</router-link>
                                    <span v-if="i != User.UserSessionCommittees.length-1">, </span>
                                </template>
                            </div>

                            <div v-if="User.UserInterimCommittees.length >0">
                                <br />
                                <div><b>Interim Committee(s):</b></div>
                                <template v-for="(s,i) in User.UserInterimCommittees">
                                    <router-link :to="'/Interim/Committee/' + s.InterimYearCommitteeId">{{s.Name}}</router-link>
                                    <span v-if="i != User.UserInterimCommittees.length-1">, </span>
                                </template>
                            </div>
                            <div v-if="User.BudgetAgency.length >0">
                                <br />
                                <div>
                                    <b>Budget Agency(s):</b>
                                </div>
                                <template v-for="(s,i) in User.BudgetAgency">
                                    <span v-if="i != User.BudgetAgency.length-1">{{s.Agency}}, </span>
                                    <span v-else>{{s.Agency}}</span>
                                </template>
                            </div>
                            <div v-if="User.RulesAgencies.length >0">
                                <br />
                                <div><b>Rules Agency(s):</b></div>
                                <template v-for="(s,i) in User.RulesAgencies">
                                    <span v-if="i != User.RulesAgencies.length-1">{{s.AgencyDivision}}, </span>
                                    <span v-else>{{s.AgencyDivision}}</span>
                                </template>
                            </div>
                        </div>
                    </div>
                </v-flex>
            </v-layout>
        </v-card-text>
    </v-card>
</template>
<script>
    import { mdiAccount } from '@mdi/js';
    import { mdiEmail } from '@mdi/js';
    import { mdiPhone } from '@mdi/js';
    export default {
        name: 'PersonCard',
        props: ['UserId', 'expand', 'UserProp', 'bigImage'],
        components: {

        },
        methods: {
            generateBackgroundImage(isSmall)
            {
                if (this.User && this.User.Picture)
                {
                    return "background-image: image-set(" +
                        "'" + this.User.Picture.toLowerCase() + "' type('image/jpeg')) " +
                        "; background-position: center center; background-size: contain;";
                }
                else
                {
                    return '';
                }
            },
            generatePictureUrl(supportWebP, isSmall)
            {
                let basePath = `/api/Persons/Picture/${this.User.PersonId}/null/${isSmall}/${supportWebP}`;
                return basePath;
            },
            LoadData()
            {
                if (this.UserProp != null)
                {
                    this.User = this.UserProp;
                    this.loading = false;
                }
                if (this.UserId != null)
                {
                    fetch('/api/Users/' + this.UserId, {
                        method: "GET",
                        credentials: "include",
                        headers: {
                            "Content-Type": "application/json"
                        },
                    }).then(res =>
                    {
                        this.loading = false;
                        if (res.status === 200 || res.status === 201)
                        {
                            return res.json();
                        }
                        else if (res.status === 404)
                        {
                            this.$router.push('/Staff/Listing');
                            return null;
                        }
                        else
                        {
                            return null;
                        }
                        throw new Error(res.statusText);
                    }).then(response =>
                    {
                        if (response)
                        {
                            this.User = EncodeDatetimes(response);
                        }
                    }).catch(error =>
                    {
                        throw new Error(error);
                    });
                }
            },
        },
        data: function ()
        {
            return {
                person: mdiAccount,
                email: mdiEmail,
                phone: mdiPhone,
                SubjectAreas: null,
                SessionCommittees: null,
                InterimCommittees: null,
                BudgetAgencies: null,
                RulesAgencies: null,
                User: {
                    FirstName: null,
                    LastName: null,
                    Picture: null,
                },
                loading: true,
            }
        },
        mounted: function ()
        {
            this.LoadData();
        },
        watch: {
            UserId: {
                handler()
                {
                    this.LoadData();
                },
            },
        },
        computed: {
            currentRoute: function ()
            {
                return this.$route.path.toLowerCase();
            },
            Title: function ()
            {
                let title = 'Loading...';
                if (this.currentRoute.includes('profile') && this.User && this.User.FirstName)
                {
                    title = this.User.FirstName + ' ' + this.User.LastName;
                }
                else if (this.currentRoute.includes('listing'))
                {
                    title = 'Staff Listing';
                }
                else if (this.currentRoute.includes('division'))
                {
                    title = 'Staff by Division';
                }
                return title;
            },
            Description: function ()
            {
                return this.Title;
            },
        },
        metaInfo()
        {
            return {
                title: this.Title,
                script: [
                    {
                        type: 'application/ld+json',
                        json: {
                            '@context': 'http://schema.org',
                            '@type': 'Person',
                            'name': this.User.FirstName + ' ' + this.User.LastName,
                            'email': this.User.Email,
                            'telephone': '605-773-3251',
                            'image': this.User.Picture
                        }
                    }],
                meta: [
                    { vmid: 'description', name: 'description', content: this.Description },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: this.Title + ' | South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'profile' },
                    // Should be the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: window.location.href },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:image', property: 'og:image', content: this.User.Picture },
                    { vmid: 'og:image:width', property: 'og:image:width', content: '100' },
                    { vmid: 'og:image:height', property: 'og:image:height', content: '130' },
                    { vmid: 'profile:first_name', property: 'profile:first_name', content: this.User.FirstName },
                    { vmid: 'profile:last_name', property: 'profile:last_name', content: this.User.LastName },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: window.location.href },
                    { vmid: 'twitter:title', name: 'twitter:title', content: this.Title + ' | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: this.Description },
                    { vmid: 'twitter:image', name: 'twitter:image', content: this.User.Picture },
                    { vmid: 'twitter:image:alt', name: 'twitter:image:alt', content: 'A Profile Picture of ' + this.User.FirstName + ' ' + this.User.LastName }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: window.location.href.split('?')[0] }
                ]
            }
        }
    };
</script>
<style>
    .profile picture,
    .profile img
    {
        width: 100%;
        height: 100%;
        object-fit: cover; /* This ensures the image scales correctly */
    }
</style>
