<template>
    <v-container fluid>
        <v-row :justify="'center'">
            <v-col cols="12" md="5">
                <v-card>
                    <v-card-text>
                        <div class="display-1 text--primary text-center" style="font-family: Iskoola Pota !important;font-variant: small-caps;">Schedule</div>
                        <v-progress-linear indeterminate v-if="loading"></v-progress-linear>
                    </v-card-text>

                    <v-skeleton-loader v-if="loading"
                                       v-for="j in 20"
                                       :loading="loading"
                                       transition="fade-transition"
                                       type="list-item-two-line">
                    </v-skeleton-loader>
                    <v-list dense v-if="Schedule.length >0">
                        <template v-for="(item, i) in Schedule">
                            <v-divider class="ma-0"></v-divider>
                            <v-list-item :key="i.text"
                                         target="_blank"
                                         two-line>
                                <v-list-item-content>

                                    <v-list-item-title v-if="item.SessionCommitteeId" class="text-wrap">
                                        <router-link aria-label="Committee" :to="'/Session/Committee/' + item.SessionCommitteeId">
                                            {{item.CommitteeFullName}}
                                        </router-link>
                                    </v-list-item-title>
                                    <v-list-item-title class="text-wrap" v-else-if="item.InterimYearCommitteeId"><router-link aria-label="Committee" :to="'/Interim/Committee/' + item.InterimYearCommitteeId">{{item.InterimYearCommitteeName}}</router-link></v-list-item-title>
                                    <v-list-item-title class="text-wrap" v-else-if="item.ConferenceCommitteeId"><router-link aria-label="Committee" :to="'/Session/ConferenceCommittees/' + item.SessionId">{{item.ConferenceCommitteeName}}</router-link></v-list-item-title>
                                    <v-list-item-title class="text-wrap" v-else>{{item.Title}}</v-list-item-title>

                                    <v-list-item-subtitle class="text-wrap" v-if="item.TBD">{{formatDate2(item.DocumentDate)}} TBD - {{item.Room}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-wrap" v-else-if="item.SessionCommitteeId">{{formatDate(item.DocumentDate)}} {{item.DocumentDate | FormatTimes}} - {{item.Room}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-wrap" v-else-if="item.InterimYearCommitteeId">{{formatDate(item.DocumentDate)}} {{item.DocumentDate | FormatTimes}} - {{item.Room}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-wrap" v-else-if="item.ConferenceCommitteeId">{{formatDate(item.DocumentDate)}} {{item.DocumentDate | FormatTimes}} - {{item.Room}}</v-list-item-subtitle>
                                    <v-list-item-subtitle class="text-wrap" v-else-if="item.DocumentDate">{{formatDate(item.DocumentDate)}} {{item.DocumentDate | FormatTimes}} - {{item.Room}}</v-list-item-subtitle>
                                </v-list-item-content>

                                <v-list-item-icon class="ml-1 mr-0" v-if="item.InterimYearCommitteeId == 472 || item.InterimYearCommitteeId == 486 || item.SessionCommitteeId == 1206 || item.SessionCommitteeId == 1193 || item.SessionCommitteeId == 1210">
                                    <v-tooltip bottom>
                                        Register to Testify
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small class="px-1" aria-label="Register to Testify" :to="'/Testify/' + item.DocumentId" color="blue" dark :disabled="!((item.InterimYearCommitteeId != null || item.SessionCommitteeId) && item.DocumentId && item.NoMeeting != true && item.InternetReady == true)" icon>
                                                <v-icon>{{mdiAccountPlus}}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-icon v-if="(item.RoomAudio && item.ShowAudio == true) && item.NoMeeting != true && (item.InternetReady == true || item.DocumentTypeId == 99)" class="ml-1 mr-0">
                                    <v-tooltip bottom>
                                        South Dakota Public Broadcasting Audio
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small class="px-1" aria-label="SDPB Audio" rel="noopener" :href="item.RoomAudio" color="blue" dark icon>
                                                <v-icon>{{mdiVolumeHigh}}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-icon class="ml-1 mr-0">
                                    <v-tooltip bottom>
                                        Committee Liveboard
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small class="px-1" aria-label="Display Board" :to="'/Session/Committee/LiveBoard/' + item.DocumentId" color="blue" dark :disabled="!(item.SessionCommitteeId != null && item.DocumentId && item.NoMeeting != true && item.InternetReady == true && item.ShowAudio == true)" icon>
                                                <v-icon>{{tv}}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </v-list-item-icon>
                                <v-list-item-icon class="ml-1 mr-0">
                                    <v-tooltip bottom>
                                        Agenda
                                        <template v-slot:activator="{ on }">
                                            <v-btn v-on="on" small class="px-1" aria-label="Agenda" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf'" color="blue" dark :disabled="!(item.DocumentId && item.InternetReady == true)" icon>
                                                <v-icon>{{description}}</v-icon>
                                            </v-btn>
                                        </template>
                                    </v-tooltip>
                                </v-list-item-icon>
                            </v-list-item>
                            <v-list-item v-if="i >= 19 && i == Schedule.length - 1">
                                <v-list-item-content>
                                    <v-list-item-title>
                                        <v-alert type="info" dense text>
                                            <router-link to="/Session/Schedule">
                                                Please see the full schedule for more events
                                            </router-link>
                                        </v-alert>
                                    </v-list-item-title>
                                    <v-list-item-subtitle>Meetings are removed from this schedule when minutes are approved.</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                    <v-alert v-else-if="!loading" dense text type="info">No Upcoming Meetings</v-alert>
                </v-card>
            </v-col>
            <v-col cols="12" md="7">
                <v-card>
                    <v-card-text>
                        <div class="display-1 text--primary text-center" style="font-family: Iskoola Pota !important;font-variant: small-caps;">Latest</div>
                        <v-progress-linear indeterminate v-if="loadingLatest"></v-progress-linear>
                    </v-card-text>
                    <v-skeleton-loader v-if="loadingLatest"
                                       v-for="j in 5"
                                       :loading="loadingLatest"
                                       transition="fade-transition"
                                       type="list-item">
                    </v-skeleton-loader>

                    <v-list dense v-if="Latest.length > 0 && !loadingLatest">
                        <template v-for="item in Latest">
                            <v-divider class="ma-0"></v-divider>
                            <v-list-item :key="item.DocumentId">
                                <v-list-item-content>
                                    <v-list-item-title class="text-wrap">
                                        <a aria-label="Press Release" v-if="item.DocumentTypeId == 48" rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/PressRelease/' + item.DocumentId + '.pdf'">{{item.Description || item.Title}}</a>
                                        <div aria-label="Press Release" v-else-if="item.DocumentTypeId == 92 && item.WebFilename == '#'">{{item.Title || item.Description}}</div>
                                        <a aria-label="Press Release" v-else-if="item.DocumentTypeId == 92" rel="noopener" :href="item.WebFilename">{{item.Title || item.Description}}</a>
                                        <a aria-label="Press Release" v-else rel="noopener" :href="$MyLRCUrl.value + 'api/Documents/' + item.DocumentId + '.pdf'">{{item.Title || item.Description}}</a>
                                    </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                    </v-list>
                    <v-alert v-else-if="!loadingLatest" dense text type="info">No recent news.</v-alert>
                </v-card>
                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-container fluid>
                                <v-row no-gutters>
                                    <v-col v-for="item in QuickButton"
                                           :key="item.Text"
                                           class=""
                                           no-gutters
                                           style="padding:6px"
                                           cols="6"
                                           sm="6"
                                           md="3">
                                        <!-- Menu Button if Multiple Links Exist -->
                                        <v-card flat tile class="d-flex" v-if="item.JsonData && item.JsonData.links && item.JsonData.links.length > 0" style="max-height: 150px;">
                                            <v-menu open-on-hover>
                                                <template v-slot:activator="{ on, attrs }">
                                                    <v-btn v-bind="attrs"
                                                           v-on="on"
                                                           x-large
                                                           :color="item.Color"
                                                           class="square"
                                                           style="height: 70%; max-height: 150px; text-align: center; flex: none; white-space: pre-line; font-family: Iskoola Pota; "
                                                           dark
                                                           block
                                                           :prepend-icon="item.JsonData.icon || ''">
                                                        <b>{{ item.Text }}</b>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-item v-for="(link, index) in item.JsonData.links"
                                                                 :key="index"
                                                                 :href="link.url.startsWith('http') ? link.url : null"
                                                                 :to="!link.url.startsWith('http') ? link.url : null"
                                                                 target="_blank"
                                                                 rel="noopener">
                                                        <v-list-item-icon v-if="link.icon">
                                                            <v-icon>{{link.icon}}</v-icon>
                                                        </v-list-item-icon>
                                                        <v-list-item-title>{{ link.text || link.url }}</v-list-item-title>
                                                    </v-list-item>
                                                </v-list>
                                            </v-menu>
                                        </v-card>

                                        <!-- Single Link Button -->
                                        <v-card flat tile class="d-flex" v-else style="max-height: 300px;">
                                            <v-btn :href="item.Url && item.Url.startsWith('http') ? item.Url : null"
                                                   :to="item.Url && !item.Url.startsWith('http') ? item.Url : null"
                                                   x-large
                                                   :color="item.Color"
                                                   class="square"
                                                   style="height: 70%; max-height: 150px; text-align: center; flex: none; white-space: pre-line; font-family: Iskoola Pota; "
                                                   dark
                                                   block
                                                   :prepend-icon="item.JsonData && item.JsonData.icon ? item.JsonData.icon : ''">
                                                <b>{{ item.Text }}</b>
                                            </v-btn>
                                        </v-card>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import moment from 'moment';
    import { mdiFileDocument } from '@mdi/js';
    import { mdiTelevision } from '@mdi/js';
    import { mdiVolumeHigh } from '@mdi/js';
    import { mdiAccountPlus } from '@mdi/js';

    export default {
        name: 'Home',
        components: {
        },
        metaInfo()
        {
            return {
                title: 'Home',
                meta: [
                    { vmid: 'description', name: 'description', content: 'The South Dakota State Legislature Home Page' },
                    // OpenGraph data (Most widely used)
                    { vmid: 'og:title', property: 'og:title', content: 'Home | South Dakota Legislature' },
                    { vmid: 'og:site_name', property: 'og:site_name', content: 'South Dakota Legislature' },
                    // The list of types is available here: http://ogp.me/#types
                    { vmid: 'og:type', property: 'og:type', content: 'website' },
                    // Should the the same as your canonical link, see below.
                    { vmid: 'og:url', property: 'og:url', content: 'https://sdlegislature.gov' },
                    { vmid: 'og:image', property: 'og:image', content: 'https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/logoshape.webp' },
                    // Often the same as your meta description, but not always.
                    { vmid: 'og:description', property: 'og:description', content: 'The South Dakota State Legislature Home Page' },

                    // Twitter card
                    { vmid: 'twitter:card', name: 'twitter:card', content: 'summary' },
                    { vmid: 'twitter:site', name: 'twitter:site', content: 'https://sdlegislature.gov' },
                    { vmid: 'twitter:title', name: 'twitter:title', content: 'Home | South Dakota Legislature' },
                    { vmid: 'twitter:description', name: 'twitter:description', content: 'The South Dakota State Legislature Home Page' },
                    // Your twitter handle, if you have one.
                    //{vmid: 'twitter:creator', name: 'twitter:creator', content: '@'}
                    { vmid: 'twitter:image:src', name: 'twitter:image:src', content: 'https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/logoshape.webp' },

                    // Google / Schema.org markup:
                    { vmid: 'Schema:name', itemprop: 'name', content: 'Home | South Dakota Legislature' },
                    { vmid: 'Schema:description', itemprop: 'description', content: 'The South Dakota State Legislature Home Page' },
                    { vmid: 'Schema:image', itemprop: 'image', content: 'https://lawmakerdocuments.blob.core.usgovcloudapi.net/photos/logoshape.webp' }
                ],
                link: [
                    { vmid: 'canonical', rel: 'canonical', href: 'https://sdlegislature.gov' }
                ]
            };
        },
        methods: {
            LoadData()
            {
                this.loading = true;
                fetch('/api/Documents/Schedule', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loading = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    if (response)
                    {
                        var tempSchedule = EncodeDatetimes(response);
                        var endSchedule = [];

                        tempSchedule.sessionMeetings.forEach(x =>
                        {
                            endSchedule.push(x);
                        });
                        this.Schedule = endSchedule.sort((a, b) => (a.DocumentDate > b.DocumentDate) ? 1 : -1);
                        this.loading = false;
                    }
                }).catch(error =>
                {
                    throw new Error(error);
                });

                this.loadingLatest = true;
                fetch('/api/Documents/DocumentType?Type=48&Type=90&Type=92&Top=10&Future=true', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                }).then(res =>
                {
                    this.loadingLatest = false;
                    if (res.status === 200 || res.status === 201)
                    {
                        return res.json();
                    }
                    else
                    {
                        return null;
                    }
                    throw new Error(res.statusText);
                }).then(response =>
                {
                    var res = EncodeDatetimes(response);
                    this.Latest = res;
                    this.loadingLatest = false;
                }).catch(error =>
                {
                    throw new Error(error);
                });

                fetch('/api/HomepageButtons', {
                    method: "GET",
                    credentials: "include",
                    headers: {
                        "Content-Type": "application/json"
                    },
                })
                    .then(res => {
                        if (res.ok) {
                            return res.json();
                        } else {
                            throw new Error(res.statusText);
                        }
                    })
                    .then(response => {
                        if (response != null) {
                            // Ensure each button's JsonData has a consistent structure
                            var res = response.map(button => {
                                let jsonData = button.JsonData;

                                // If JsonData is a string, parse it; if null, initialize with default structure
                                if (typeof jsonData === 'string' && jsonData) {
                                    jsonData = JSON.parse(jsonData);
                                }
                                if (!jsonData || typeof jsonData !== 'object') {
                                    jsonData = { icon: '', links: [] };
                                }

                                return {
                                    ...button,
                                    JsonData: jsonData
                                };
                            });

                            this.QuickButton = EncodeDatetimes(res);  // Assuming EncodeDatetimes handles date formatting
                            this.loadingButtons = false;
                        }
                    })
                    .catch(error => {
                        throw new Error(error);
                    });
            },
            formatDate(Date)
            {
                return moment(Date).format('M/DD');
            },
            formatDate2(Date)
            {
                return moment(Date).add(8, 'hours').format('M/DD');
            },
            FormatDateCompare: function (Date)
            {
                return moment(Date).format('MM/DD/YYYY');
            },
            formatTime(Date)
            {
                return moment(Date).format('h:mm A');

            },
            onResize()
            {
                this.windowWidth = window.innerWidth;
            }
        },
        data: function ()
        {
            return {
                mdiAccountPlus,
                description: mdiFileDocument,
                tv: mdiTelevision,
                mdiVolumeHigh,
                Schedule: [],
                loading: true,
                loadingLatest: true,
                loadingButtons: true,
                Latest: [],
                attrs: {
                    class: 'mb-6',
                    boilerplate: true,
                    elevation: 2,
                },
                QuickButton: [
                    { Text: '2021 Bills', Url: '/Session/Bills/44', Color: '#1C453B' },
                    { Text: 'Codified Laws', Url: '/Statutes', Color: '#757575' },
                    { Text: '2021 Legislators', Url: '/Legislators/Listing/44', Color: '#783037' },
                    { Text: 'Housing Questionnaire', Url: '/Session/General/Housing/43', Color: '#757575' },
                    { Text: '2020 Interim', Url: '/Interim/Schedule/43', Color: '#783037' },
                    { Text: '2021 Calendar', Url: '/Session/Schedule/44', Color: '#1C453B' },
                ],
                windowWidth: window.innerWidth,

            };
        },
        mounted: function ()
        {
            this.LoadData();
            window.addEventListener('resize', this.onResize);
        },
        computed: {
            sortedRows: function ()
            {
                let resultRows = this.bills;
                let stack = this.sortStack;
                resultRows.sort(function (a, b)
                {
                    for (let i in stack)
                    {
                        let key = stack[i].value;
                        let desc = stack[i].descending;
                        if (a[key] > b[key]) return desc ? -1 : 1;
                        else if (a[key] < b[key]) return desc ? 1 : -1;
                        else continue;
                    }
                    return 0;
                });
                return resultRows;
            },
            Dark: function ()
            {
                return app.$data.Dark;
            },
            TodaysDate: function ()
            {
                return moment().format('MM/DD/YYYY');
            },
            isMobile()
            {
                return this.windowWidth < 960;
            },

        }
    };
</script>

<style>
</style>